import axios from "axios";
import { Fragment, lazy, Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { getNavpu } from "../container/redux/actions/navpuAction";

import Loader from "container/shared/Loading";
import ReactGA from "react-ga";
const TRACKING_ID = "UA-257662371-1";

ReactGA.initialize(TRACKING_ID);

const Home = lazy(() => import("../container/pages/home/Home"));
const NotFound = lazy(() =>
  import("../container/pages/home/notFound/NotFound")
);
const About = lazy(() => import("../container/pages/about/AboutUs"));

const CategoryDetails = lazy(() =>
  import("../container/pages/funds/CategoryDetails")
);
const CategoryUitfMf = lazy(() =>
  import("../container/pages/funds/CategoryUitfMf")
);

const BlogPage = lazy(() => import("../container/pages/blog/BlogPage"));

// Blogs
const KN1 = lazy(() => import("../container/pages/blog/KN1"));
const LeadersTraining = lazy(() =>
  import("../container/pages/blog/LeadersTraining")
);

const GeneralAssembly = lazy(() =>
  import("../container/pages/blog/GeneralAssembly")
);

// Mutual Fund Details
const FundDetails = lazy(() =>
  import("../container/pages/fundDetails/FundDetails")
);
const FormsIndividual = lazy(() =>
  import("../container/pages/forms/Individual")
);
const FormsCorporate = lazy(() => import("../container/pages/forms/Corporate"));

const MfFormsIndividual = lazy(() =>
  import("../container/pages/forms/mutual-funds/MfIndividualForms")
);
const MfFormsCorporate = lazy(() =>
  import("../container/pages/forms/mutual-funds/MfCorporateForms")
);
const UitfFormsIndividual = lazy(() =>
  import("../container/pages/forms/uitf/UitfIndividualForms")
);
const UitfFormsCorporate = lazy(() =>
  import("../container/pages/forms/uitf/UitfCorporateForms")
);
// How to
const HowToInvest = lazy(() => import("../container/pages/howto/HowToInvest"));
const HowToRedeem = lazy(() => import("../container/pages/howto/HowToRedeem"));
const HowToFS = lazy(() => import("../container/pages/howto/HowToFS"));
const Invest = lazy(() => import("../container/pages/howto/content/Invest"));
const Redeem = lazy(() => import("../container/pages/howto/content/Redeem"));
const FundSwitch = lazy(() =>
  import("../container/pages/howto/content/FundSwitch")
);
const ContactUs = lazy(() =>
  import("../container/pages/new-contact/ContactUs")
);
// Payment Facilities
const PaymentFacilities = lazy(() =>
  import("../container/pages/payment/Payment")
);
const FAQ = lazy(() => import("../container/pages/faq/FAQ"));
// What We do
const WhatWeDo = lazy(() => import("../container/pages/whatWeDo/WhatWe"));
// Online Application
const InvestOnline = lazy(() =>
  import("../container/pages/online/InvestOnline")
);
const RedeemOnline = lazy(() =>
  import("../container/pages/online/RedeemOnline")
);
const FSOnline = lazy(() => import("../container/pages/online/FSOnline"));
// Nvestmo
const Nvestmo = lazy(() => import("../container/pages/nvestmo/Nvestmo"));
// Daily NAVPU
const Navpu = lazy(() => import("../container/pages/navpu/Navpu"));
const Daily = lazy(() => import("../container/pages/navpu/Daily"));
// Career
const Career = lazy(() => import("../container/pages/careers/Careers"));
const Corporate = lazy(() => import("../container/pages/corporate/Corporate"));
const NewsEvents = lazy(() => import("../container/pages/events/NewsEvents"));
// ADA
const ADA = lazy(() => import("../container/pages/ada/Ada"));
const InvestMGMT = lazy(() =>
  import("../container/pages/home/banner/InvestMgmt")
);
const WealthMGMT = lazy(() =>
  import("../container/pages/home/schedule-adviser/ScheduleAdviser")
);

const RSP = lazy(() => import("../container/pages/home/banner/RSP"));
const TalkUS = lazy(() => import("../container/pages/home/banner/TalkUs"));
// Customer Journey
const JourneyBanner = lazy(() =>
  import("../container/pages/customerJourney/StartPage")
);
const KindInvestor = lazy(() =>
  import("../container/pages/customerJourney/KindInvestor")
);
const FinancialGoal = lazy(() =>
  import("../container/pages/customerJourney/FinancialGoal")
);
const RiskProfile = lazy(() =>
  import("../container/pages/customerJourney/RiskProfile")
);
const CisolTalkPage = lazy(() =>
  import("../container/pages/customerJourney/CisolTalk")
);
const Goal = lazy(() =>
  import("../container/pages/customerJourney/DoYouHaveGoal")
);
const Questionaire = lazy(() =>
  import("../container/pages/customerJourney/Questionaire")
);
const CustomGoal = lazy(() =>
  import("../container/pages/customerJourney/CustomGoal")
);
const RetirementGoal = lazy(() =>
  import("../container/pages/customerJourney/retirement/RetirementPlan")
);
const CollegeGoal = lazy(() =>
  import("../container/pages/customerJourney/collegePlan/CollegePlan")
);
const CollegeSchool = lazy(() =>
  import("../container/pages/customerJourney/collegePlan/CollegePlanSchool")
);
const EstimatedCollege = lazy(() =>
  import("../container/pages/customerJourney/collegePlan/EstimatedAmount")
);
const FundTypeRetire = lazy(() =>
  import("../container/pages/customerJourney/retirement/RetireRiskFund")
);
const FundTypeCollege = lazy(() =>
  import("../container/pages/customerJourney/collegePlan/FundTypeCollege")
);
const FundTypeInvest = lazy(() =>
  import("../container/pages/customerJourney/investPlan/FundTypeInvest")
);
const FundTypeOptions = lazy(() =>
  import("../container/pages/customerJourney/fundType/FundTypeConservative")
);
const FundTypeModerate = lazy(() =>
  import("../container/pages/customerJourney/fundType/FundTypeModerate")
);
const FundTypeAggressive = lazy(() =>
  import("../container/pages/customerJourney/fundType/FundTypeAggressive")
);
const InvestmentPlan = lazy(() =>
  import("../container/pages/customerJourney/investPlan/InvestmentPlan")
);
const PurchaseAsset = lazy(() =>
  import("../container/pages/customerJourney/investPlan/PurchaseAsset")
);
const RetirementEstimated = lazy(() =>
  import("../container/pages/customerJourney/retirement/RetirementEstimated")
);
const CreateGoal = lazy(() =>
  import("../container/pages/customerJourney/custom/CreateGoal")
);
const KycPage = lazy(() =>
  import("../container/pages/customerJourney/kyc/KYCTab")
);

export default function RoutesMain() {
  const dispatch = useDispatch();

  const fetchNAVPU = async () => {
    try {
      let agmaIndex;
      let temp;
      const response = await axios({
        url: "https://api2.nvestmo.com/api/affinity-web/navpu",
      });

      const navpuListClone = response.data.filter(
        (navpu) => navpu.key !== "first_metro"
      );

      navpuListClone?.forEach((navpu, index) => {
        if (navpu.key === "agma_fund") {
          agmaIndex = index;
          temp = navpu;
        }
      });

      navpuListClone.splice(agmaIndex, 1);
      navpuListClone.unshift(temp);

      dispatch(getNavpu(navpuListClone));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    let isMounted = false;

    if (!isMounted) fetchNAVPU();

    ReactGA.pageview(window.location.pathname + window.location.search);

    return () => {
      isMounted = true;
    };
  }, []);

  return (
    <Fragment>
      <Suspense fallback={Loader()}>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route
            path="/category/:fundClass/:categoryName"
            element={<CategoryDetails />}
          />
          <Route
            path="/category-all/:categoryName"
            element={<CategoryUitfMf />}
          />
          <Route path="/fund-details/:fundName" element={<FundDetails />} />
          <Route path="/blog" exact element={<BlogPage />} />
          <Route path="/blog/kn1" exact element={<KN1 />} />
          <Route
            path="/blog/leaders_training"
            exact
            element={<LeadersTraining />}
          />
          <Route
            path="/blog/general_assembly"
            exact
            element={<GeneralAssembly />}
          />
          <Route path="/individual-forms/*" element={<FormsIndividual />}>
            <Route path="mutual-fund" element={<MfFormsIndividual />} />
            <Route path="uitf" element={<UitfFormsIndividual />} />
          </Route>
          <Route path="/corporate-forms/*" element={<FormsCorporate />}>
            <Route path="mutual-fund" element={<MfFormsCorporate />} />
            <Route path="uitf" element={<UitfFormsCorporate />} />
          </Route>
          {/* How to */}
          <Route path="/how-to-invest" element={<HowToInvest />} />
          <Route path="/how-to-redeem" element={<HowToRedeem />} />
          <Route path="/how-to-fs" element={<HowToFS />} />
          <Route path="/invest" element={<Invest />} />
          <Route path="/redeem" element={<Redeem />} />
          <Route path="/fund-switch" element={<FundSwitch />} />
          {/* Contact Us */}
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/careers" element={<Career />} />
          {/* Payment Facilites */}
          <Route path="/payment" element={<PaymentFacilities />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/what-we-do" element={<WhatWeDo />} />
          <Route path="/invest-online" element={<InvestOnline />} />
          <Route path="/redeem-online" element={<RedeemOnline />} />
          <Route path="/fs-online" element={<FSOnline />} />
          {/* Nvestmo */}
          <Route path="/nvestmo" element={<Nvestmo />} />
          <Route path="/navpu" element={<Navpu />} />
          <Route path="/daily-navpu" element={<Daily />} />
          {/* Corporate Retirement */}
          <Route path="/corporate-retirement" element={<Corporate />} />
          <Route path="/news-events" element={<NewsEvents />} />
          {/* ADA */}
          <Route path="/ada-forms" element={<ADA />} />
          <Route path="/invest-mgmt" element={<InvestMGMT />} />
          <Route path="/wealth-mgmt" element={<WealthMGMT />} />
          <Route path="/rsp" element={<RSP />} />
          <Route path="/talk-to-us" element={<TalkUS />} />
          {/* Customer journey */}
          <Route path="/customer-journey" element={<JourneyBanner />} />
          <Route path="/goal" element={<Goal />} />
          <Route path="/risk-profile" element={<KindInvestor />} />
          <Route path="/financial-goal" element={<FinancialGoal />} />
          <Route path="/risk-assessment" element={<RiskProfile />} />
          <Route path="/cisol-talk" element={<CisolTalkPage />} />
          <Route path="/custom-goal" element={<CustomGoal />} />
          <Route path="/create-goal" element={<CreateGoal />} />
          {/* College plan */}
          <Route path="/college-goal" element={<CollegeGoal />} />
          <Route path="/college-school" element={<CollegeSchool />} />
          <Route path="/college-estimated" element={<EstimatedCollege />} />
          <Route path="/fund-type-college" element={<FundTypeCollege />} />
          {/* Investment Plan */}
          <Route path="/invest-plan" element={<InvestmentPlan />} />
          <Route path="/purchase-plan" element={<PurchaseAsset />} />
          <Route path="/questionaire" element={<Questionaire />} />
          <Route path="/fund-type-invest" element={<FundTypeInvest />} />
          {/* Retirement Plan */}
          <Route path="/retirement-goal" element={<RetirementGoal />} />
          <Route path="/retire-estimated" element={<RetirementEstimated />} />
          <Route path="/fund-type-retire" element={<FundTypeRetire />} />
          {/* Fund Type */}
          <Route path="/fund-type-conservative" element={<FundTypeOptions />} />
          <Route path="/fund-type-moderate" element={<FundTypeModerate />} />
          <Route
            path="/fund-type-aggressive"
            element={<FundTypeAggressive />}
          />
          <Route path="/kyc" element={<KycPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </Fragment>
  );
}
